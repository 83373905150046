import React from 'react'

import '../css/skeleton.css'
import '../css/normalize.css'
import '../css/components.css'

function Footer() {
    return (
        <div className="Footer container">
            <p>Artiface 2022 - Template by <a href="//github.com/heysagnik/Linkees">Sagnik</a></p>
        </div>
    )
}

export default Footer
